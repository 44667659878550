import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDq8Dbpd4YugZQpEObAxABYIiKovN4LhOg",
  authDomain: "fishbowl-5e314.firebaseapp.com",
  projectId: "fishbowl-5e314",
  storageBucket: "fishbowl-5e314.appspot.com",
  messagingSenderId: "168203421532",
  appId: "1:168203421532:web:03a5299b5239ae128aa399",
  measurementId: "G-CZR8Y4HV6J"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);