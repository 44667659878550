import './App.css';

import { Navbar } from './components/navbar';
import { Prompts } from './components/prompts';

import { auth } from './config/firebase'
import { onAuthStateChanged, getAuth, signInAnonymously } from "firebase/auth";

onAuthStateChanged(auth, (user) => {
  if (user === null){
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        console.log("Succesfully Signed in Anonymously")
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert('An Authenticatin error occured. Please clear your cache, and local storage, then refresh this page to continue. Error code: `' + errorCode + '` Error Message:' + errorMessage + '`');
      });
  }
})


function App() {
  return (
    <div className="appContainer">
      
      <Navbar />

      <div className="resultsContainer">
        <div className="resultsContainerGrabber"></div>
        <Prompts />
      </div>

    </div>
  );
}

export default App;
